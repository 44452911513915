import React from 'react'

const excludedBrands = ['NONA', 'UNBR']

export const CardSchema = props => {
  const { brands, gtin, id, image, title, fullPriceProps } = props
  const { price, unitPrice, unitsInPacket } = fullPriceProps
  const brand = brands?.find(b => !excludedBrands.includes(b?.code))

  // DO NOT LEAVE COMMENTS INSIDE JSON!!!
  const json = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: title,
    image,
    gtin,
    sku: id,
    ...(!!brand?.length && {
      brand: {
        '@type': 'Brand',
        name: brand.name
      }
    }),

    offers: {
      '@type': 'Offer',
      priceCurrency: 'EUR',
      price,
      ...(unitPrice && {
        eligibleQuantity: {
          '@type': 'QuantitativeValue',
          value: unitsInPacket,
          unitCode: 'MTK'
        },
        eligibleTransactionVolume: {
          '@type': 'PriceSpecification',
          price: unitPrice,
          priceCurrency: 'EUR',
          eligibleQuantity: {
            '@type': 'QuantitativeValue',
            value: 1,
            unitCode: 'MTK'
          }
        }
      })
    }
  }
  return (
    <script
      style={{ display: 'none' }}
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
    />
  )
}
