import { PriceBannerIcons } from 'chemistry'

export const extractPriceProps = ({
  oldUnitPrice,
  oldPrice,
  unitPrice,
  price,
  packetText,
  units,
  packagePriceFirst,
  showPriceLabel,
  labelText,
  percentage,
  isEcocheque,
  isSustainability
}) => {
  const icons = []
  if (isEcocheque) icons.push(PriceBannerIcons.ecochequeBannerIcons['32x32'])
  if (isSustainability) icons.push(PriceBannerIcons.sustainabilityBannerIcons['32x32'])

  return {
    oldUnitPrice,
    oldPrice,
    unitPrice,
    price,
    packetText,
    units,
    packagePriceFirst,
    showPriceLabel,
    labelText,
    percentage,
    icons
  }
}
