export const getProductCountText = (t, quantity) =>
  parseInt(quantity, 10) === 1 ? t('lister.products.single') : t('lister.products.multiple')

export const replaceUnitsInTranslation = t => {
  const unitsCodes = { '&#13217;': '㎡' }

  return Object.keys(unitsCodes).reduce((acc, key) => acc.replace(key, unitsCodes[key] || key), t)
}

export const getProductListTranslations = t => ({
  discount: t('lister.products.discount.label'),
  packet: t('lister.products.packet'),
  vlp: t('lister.products.vlp'),
  // comes as per &#13217;
  units: replaceUnitsInTranslation(t('lister.products.units')),
  onlineOnly: t('lister.products.online_only'),
  temporarilyDisabled: t('lister.products.temp_disabled'),
  ecocheque: t('lister.products.ecocheque'),
  praxisPlusLabel: t('lister.products.praxisPlusLabel'),
  praxisPlusLabelKeuzekorting: t('lister.products.praxisPlusLabelKeuzekorting')
})

export const getPromotionsTextsTranslations = t => ({
  promoHalf: t('js.views.promotions.half_price'),
  promoFree: t('js.views.promotions.free'),
  promoBuy: t('js.views.promotions.buy'),
  promoGet: t('js.views.promotions.get'),
  promoFor: t('js.views.promotions.for'),
  promoOn: t('js.views.promotions.on'),
  promoDiscount: t('js.views.promotions.discount'),
  promoWith: t('js.views.promotions.with'),
  promoProduct: t('js.views.promotions.product'),
  promoNth: t('js.views.promotions.nth')
})
