import React from 'react'
import { Flex, Text, MOBILE } from 'chemistry'
import { StarSvg } from 'client/common/components/StarSvg/StarSvg'

export const RatingStars = ({ rating, label, productId }) => {
  const boxHeight = '24px'
  return (
    <Flex
      height={boxHeight}
      justify='start'
      sx={{
        marginBottom: '12px',
        [MOBILE]: {
          marginBottom: '8px'
        }
      }}
    >
      <Flex padding='4px 8px 4px 4px'>
        {Array.from(Array(5).keys()).map((_, index) => {
          const starIndex = index + 1
          const offsetValue = starIndex <= rating ? '100%' : `${(rating % 1).toFixed(1) * 100}%`
          return (
            <StarSvg
              key={`star${starIndex}`}
              id={`star${starIndex}-${productId}`}
              offsetValue={offsetValue}
              prominent={starIndex <= rating + 1}
            />
          )
        })}
      </Flex>
      {Boolean(label) && <Text lineHeight={boxHeight}>{label}</Text>}
    </Flex>
  )
}
