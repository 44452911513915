const GRAY_STATUSES = [
  'Bestelbaar in de winkel',
  'Sur commande en magasin',
  'Te bestellen in winkel',
  'Sur commande en magasin'
]
const AVAILABLE_SOON_STATUSES = ['Binnenkort verkrijgbaar', 'Bientôt disponible']

export const statusColorMapping = (availabilityStatus, disabledKey) => {
  if (disabledKey) return 'orange'
  if (AVAILABLE_SOON_STATUSES.includes(availabilityStatus)) return 'orange'
  if (GRAY_STATUSES.includes(availabilityStatus)) return 'gray'
  return 'green'
}
