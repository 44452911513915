import React from 'react'
import { chemistryTheme } from 'chemistry'

export const StarSvg = ({ prominent, offsetValue, id, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15' {...props}>
    <defs>
      <linearGradient id={`grad-${id}`}>
        <stop offset='0%' stopColor={prominent ? chemistryTheme.colors.yellow[500] : chemistryTheme.colors.light} />
        <stop
          offset={offsetValue}
          stopColor={prominent ? chemistryTheme.colors.yellow[500] : chemistryTheme.colors.light}
        />
        <stop offset={offsetValue} stopColor={chemistryTheme.colors.light} />
        <stop offset='100%' stopColor={chemistryTheme.colors.light} />
      </linearGradient>
    </defs>
    <path
      fill={`url(#grad-${id})`}
      d='M3.39002 14.0593C3.20068 14.1735 2.96713 14.0038 3.0174 13.7884L4.08876 9.19893C4.10986 9.10854 4.07915 9.0139 4.00898 8.95313L0.446753 5.86773C0.279755 5.72308 0.368717 5.44879 0.588821 5.42969L5.28101 5.02262C5.37317 5.01462 5.45337 4.9564 5.48952 4.87125L7.32758 0.542006C7.4138 0.338941 7.7016 0.338941 7.78782 0.542007L9.62588 4.87125C9.66203 4.9564 9.74224 5.01462 9.83439 5.02262L14.5266 5.42969C14.7467 5.44879 14.8356 5.72308 14.6686 5.86773L11.1064 8.95313C11.0363 9.0139 11.0055 9.10854 11.0266 9.19893L12.098 13.7884C12.1483 14.0038 11.9147 14.1735 11.7254 14.0593L7.68687 11.6222C7.60743 11.5743 7.50797 11.5743 7.42853 11.6222L3.39002 14.0593Z'
    />
  </svg>
)
