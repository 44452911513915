import { getDiscountPercentage, isPromotionWithLoyalty, PRAXIS_PLUS_LIKE_DISCOUNT } from 'chemistry'

export const extractDiscountProps = (props, promotionType, promo = {}) => {
  const { regular, t, texts } = props

  const discount = getDiscountPercentage(props)
  const yellow =
    discount?.isYellow ||
    (regular?.price && promotionType === PRAXIS_PLUS_LIKE_DISCOUNT) ||
    isPromotionWithLoyalty(promo)

  return {
    text: text(discount, t.discount),
    suffix: '%',
    discount: discount?.promoValue || discount,
    yellow,
    texts,
    showDiscount: !!discount
  }
}

const text = (discount, t) => discount?.promoDescription || t
